import { ErrorMessage } from "../common/error-msg";
import InputField from "../fields/InputField";
import SelectField from "../fields/SelectField";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Edit } from "react-feather";
import {
  Input,
  Label,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  useUpdateCategoryMutation,
} from "../../redux/queries";
import { successToast } from "utils";
import { errorToast } from "utils";
import { yup } from "utils/constants";
import { domainsOptions } from "utils/data/options";
import { useModal } from "utils/useModal";
import TextField from "components/fields/TextField";
import DropZone from "components/dropzone";

const validationSchema = yup.object().shape({
  name: yup.string().label("Name").required(),
  nameFr: yup.string().label("Name").required(),
  logo: yup.mixed().required("Logo is required"),
  description: yup.string().label("Description").required(),
  descriptionFr:yup.string().label("Description").required(),
});

const EditCategoryModal = ({ user }) => {
  console.log("🚀 ~ EditCategoryModal ~ user:", user);
  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    name: "",
    nameFr: "",
    logo: null,
    description: "",
    descriptionFr: "",
  });
  const modal = useModal();

  const [mutate] = useUpdateCategoryMutation();

  const handleOpenModal = () => {
    const { _id, name, nameFr, description, descriptionFr, logo } = user;

    const values = {
      id: _id,
      name,
      nameFr,
      description,
      descriptionFr,
      logo: [logo],
    };
    setSelectedCategory(values);
    modal.open();
  };

  const handleSubmit = async (values) => {
    try {
      const res = await mutate(values).unwrap();
      console.log("🚀 ~ handleSubmit ~ values:", values)
      successToast(res.message);
      modal.close();
    } catch (error) {
      errorToast(error.message);
    }
  };
return (
    <>
      <span
        className="flex items-center gap-3"
        role="button"
        onClick={handleOpenModal}
      >
        <Edit className="me-2" size={15} />
      </span>
      <Modal isOpen={modal.show} toggle={modal.toggle}>
        <ModalHeader toggle={modal.toggle}>Edit Category</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={selectedCategory}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
                  <div className="px-3 py-3">
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <InputField
                            label="name"
                            placeholder="Enter Name"
                            type="text"
                            name="name"
                            value={values?.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage error={errors.name} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <InputField
                            label="Name (French)"
                            placeholder="Enter Name (French)"
                            type="text"
                            name="nameFr"
                            value={values?.nameFr}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage error={errors?.nameFr} />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Field
                            label="Description"
                            placeholder="Enter Description "
                            type="text"
                            name="description"
                            rows={5}
                            component={TextField}
                          />
                          <ErrorMessage error={errors.description} />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup>
                          <Field
                            label="Description (French)"
                            placeholder="Enter Description "
                            type="text"
                            name="descriptionFr"
                            rows={5}
                            component={TextField}
                          />

                          <ErrorMessage error={errors.descriptionFr} />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <DropZone
                            setFieldValue={setFieldValue}
                            errors={errors?.logo}
                            values={values}
                            label="Add a Logo"
                            name="logo"
                            multiple={false}
                            accept={{
                              "image/*": [".png", ".jpg",".jpeg", ".svg", ".webp"],
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditCategoryModal;
