import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useState, useEffect } from "react";
import moment from "moment";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { errorToast, successToast } from "utils";
import { Button, Input, Label } from "reactstrap";
import ReactSelect from "react-select";
import { convertToFormData } from "utils";
import { useAdminUpdateUserMutation, useGetLocationsOptionsQuery, useGetUserByIdQuery } from "../../../redux/queries";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from 'formik';

const AccountsPersonalInfo = () => {
  const { id } = useParams(); // Extract ID from route params

  const [locationOptions, setLocationOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    avatar: null,
    email: "",
    domain: "",
    designation: "",
    phoneNumber: "",
    location: null,
    dateOfBirth: "",
    shortDescription: "",
    professionalBackground: "",
  });

  const { data: user } = useGetUserByIdQuery(id);
  const [updateUser, { isLoading: isUpdating }] = useAdminUpdateUserMutation();

  // Fetch location options
  const { data: locationsData, isSuccess } = useGetLocationsOptionsQuery();

  useEffect(() => {
    if (isSuccess && locationsData) {
      setLocationOptions(locationsData.map(option => ({
        value: option.value,
        label: option.label,
      })));
    }
  }, [isSuccess, locationsData]);

  useEffect(() => {
    if (user) {
      setInitialValues({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        avatar: user.avatar,
        email: user.email || "",
        domain: user.domain || "",
        designation: user.designation || "",
        phoneNumber: user.phoneNumber || "",
        location: locationOptions.find(option => option.value === user.location) || null,
        dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth).format('YYYY-MM-DD') : "",
        shortDescription: user.services?.[0]?.shortDescription || "",
        professionalBackground: user.services?.[0]?.professionalBackground || "",
      });
    }
  }, [user, locationOptions]);

  const handleSubmit = async (values) => {
    const newValues = {
      ...values,
      location: values?.location?.value
    }

    const formData = new FormData();

    for (const key in newValues) {
      if (newValues[key]) {
        formData.append(key, newValues[key]);
      }
    }

    try {
      const res = await updateUser({ id, formData }).unwrap();
      successToast(res.message);
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="bg-white rounded-lg w-full shadow-xl flex flex-col gap-5 px-5 py-10" style={{ boxShadow: "0px 0px 20px 0px #101E730F" }}>
      <h1 className="text-[20px] font-BeNational-700">Personal Information</h1>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form className="grid grid-cols-12 gap-5 items-center">
            <div className="col-span-6">
              <Label htmlFor="first-name" required>First Name</Label>
              <Field name="firstName">
                {({ field }) => <Input {...field} type="text" id="first-name" placeholder="Enter your first name" />}
              </Field>
            </div>
            <div className="col-span-6">
              <Label htmlFor="last-name" required>Last Name</Label>
              <Field name="lastName">
                {({ field }) => <Input {...field} type="text" id="last-name" placeholder="Enter your last name" />}
              </Field>
            </div>
            <div className="col-span-6">
              <Label htmlFor="avatar">Avatar</Label>
              <Input
                type="file"
                multiple={false}
                id="avatar"
                onChange={(event) => setFieldValue("avatar", event.target.files[0])}
              />
            </div>
            <div className="col-span-6">
              <Label htmlFor="email">Email</Label>
              <Input value={values.email} readOnly type="email" id="email" placeholder="Your Email" />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <Label required htmlFor="phone-number">Phone Number</Label>
              <PhoneInput
                value={values.phoneNumber}
                onChange={(value) => setFieldValue("phoneNumber", value)}
                placeholder="Enter your phone number"
                // className="bg-none"
                className="flex items-center w-full py-1 px-3 placeholder:text-gray-400 text-black  ring-1 ring-gray-300 focus:ring-2 transition focus:ring-red-500 rounded-lg tracking-wide custom-phone-input"
              />
            </div>
            <div className="col-span-6">
              <Label htmlFor="location">Location</Label>
              <ReactSelect
                value={values.location}
                onChange={(selectedOption) => setFieldValue("location", selectedOption)}
                inputId="location"
                options={locationOptions}
              />
            </div>
            <div className="col-span-6">
              <Label htmlFor="date-of-birth" required>Date of Birth</Label>
              <Field name="dateOfBirth">
                {({ field }) => <Input {...field} type="date" id="date-of-birth" />}
              </Field>
            </div>
            <div className="col-span-12">
              <Label htmlFor="short-description">Short Description</Label>
              <Field name="shortDescription">
                {({ field }) => <textarea {...field} rows="3" id="short-description" placeholder="Tell us about your background." className="w-full p-2 border rounded" />}
              </Field>
            </div>
            <div className="col-span-12">
              <Label htmlFor="professionalBackground">Background Overview</Label>
              <ReactQuill
                value={values.professionalBackground}
                onChange={(value) => setFieldValue("professionalBackground", value)}
                placeholder="Tell us about your background overview."
                className="react-quill h-72"
                style={{ height: '400px' }} 
                theme="snow"
              />
            </div>
            <div className="col-span-12 flex justify-end">
              <Button type="submit" disabled={isUpdating}>Save</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountsPersonalInfo;
