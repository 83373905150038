import Card from "components/card";
import React from "react";
import { Link } from "react-router-dom";

const FAQs = () => {
  return (
    <>
      <Card extra="mt-4">
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            FAQs
          </h4>
          <Link className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
            Add New FAQ
          </Link>
        </div>
        {/* <div className="mt-2">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item rounded-2 my-1">
              <h2
                className="accordion-header border-bottom rounded-2"
                id="flush-heading"
              >
                <button
                  className="accordion-button collapsed rounded-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse"
                  aria-expanded="false"
                  aria-controls="flush-collapse"
                >
                  Have you any question?
                </button>
              </h2>
              <div
                id="flush-collapse"
                className="accordion-collapse rounded-2 collapse"
                aria-labelledby="flush-heading"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body rounded-2">
                  {" "}
                  I don't have any question
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Card>
    </>
  );
};

export default FAQs;
