import Card from "components/card";
import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import { Link } from "react-router-dom";
import { Button } from "components/ui-elements/Button";
import UserList from "components/UserList";

const AllUsers = () => {
  const [filters, setFilters] = useState({});

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inActive" },
  ];
  const roleOptions = [
    { label: "Member", value: "member" },
    { label: "Professional", value: "professional" },
    { label: "Family Member", value: "familyMember" },
  ];

  const handleSubmit = (values) => {
    setFilters(values);
  };

  return (
    <>
      <Card extra="mt-4 !z-5 overflow-hidden">
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            All Users
          </h4>
          <Link to="/admin/add-user">
            <Button>Add User</Button>
          </Link>
        </div>
        <div className="px-4 pt-4">
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ name: "", status: "", role: "" }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <div className="grid grid-cols-4 gap-3 ">
                  <div>
                    <Field
                      label="Search By Name"
                      name="name"
                      type="text"
                      placeholder="Enter name"
                      component={InputField}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="role"
                      class="mb-2 mt-1 block text-sm font-bold text-gray-900 dark:text-white"
                    >
                      Search By Role
                    </label>
                    <Field
                      name="role"
                      selectorOptions={roleOptions}
                      selectorLabelKey="label"
                      selectorValueKey="value"
                      placeholder="role"
                      component={SelectField}
                    />
                  </div>
                  {/* <div>
                    <label
                      htmlFor="status"
                      class="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
                    >
                      Status
                    </label>
                    <Field
                      name="status"
                      selectorOptions={statusOptions}
                      selectorLabelKey="label"
                      selectorValueKey="value"
                      placeholder="status"
                      component={SelectField}
                    />
                  </div> */}
                  <div className="flex items-end">
                    <button
                      type="submit"
                      // disabled={isSubmitting}
                      className="linear rounded-[20px] bg-brand-900 mb-1 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <UserList setFilters={setFilters} filters={filters} />
      </Card>
    </>
  );
};

export default AllUsers;
