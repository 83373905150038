import Card from "components/card";
import React, { useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import DataTable from "../dataTable/reactDataTable";
import { Edit } from "react-feather";
import { Calendar } from "react-calendar";
import { Field, Form, Formik } from "formik";
import InputField from "../fields/InputField";
import { Button } from "../ui-elements/Button";
import { useDeleteCategoryMutation, useGetCategoriesQuery } from "../../redux/queries";
import moment from "moment";
import DeleteModal from "../AppModal/deleteModal";
import { Link } from "react-router-dom";
import SelectField from "../fields/SelectField";
import EditCategoryModal from "./EditCategoryModal";
import CategoryList from "./categoryList";

const columns = [
  {
    name: "Category Name",
    maxWidth: "350px",
    selector: (row) => (
      <div className="flex items-center gap-2 py-2 ">
        <img
          class="h-14 w-14 rounded"
          src={row?.logo ? row?.logo : "/assets/images/professional-man.jpeg"}
          onError={(e) => {

            e.target.src = "/assets/images/notfound.svg"
          }}
          alt="logo"
        />
        <div>
          <p className="font-semibold ">{row?.name}</p>
        </div>
      </div>
    ),
  },
  {
    name: "Services",
    selector: (row) => (
      <div className="flex items-center gap-1">
        <span className="font-semibold capitalize"> 0 </span>
      </div>
    ),
  },
  {
    name: "Created Date",
    selector: (row) => <span>{moment(row.createdAt).format("DD-MM-YYYY HH:MM")}</span>,
  },
  {
    name: "Status",
    selector: (row) => (
      <div className="flex items-center gap-2">
        <span
          className={`${row.status === "active" ? "badge-success" : "badge-danger"
            } capitalize `}
        >
          {row.status}
        </span>
      </div>
    ),
  },
  {
    name: "Actions",
    selector: (row) => (
      <div className="flex gap-3">
        <div
          className="flex gap-3"
          style={{ alignItems: "center" }}
          onClick={(e) => {
            e.preventDefault();
            //   handleEditUser(row);
            //   toggle();
          }}
        >
          {/* <Edit className="me-2" size={15} /> */}
          <EditCategoryModal user={row} />
        </div>
        <DeleteModal id={row?._id}
          useDeleteMutation={useDeleteCategoryMutation}
          message={"Are you sure you want to delete this user?"}
        />
      </div>
    ),
  },
];
const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inActive" },
];
const DomainCategories = ({ domain }) => {
  const [filters, setFilters] = useState({ domain: domain })
  const handleSubmit = (values) => {
    console.log(values,"llllllllllllllllll")
    setFilters(values);
  };
  return (
    <>
      <Card extra={"mt-4 !z-5 overflow-hidden"}>
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            All {domain} Categories
          </h4>
          <Link
            to={`/admin/add-${domain}-category`}
          >
            <Button>Add Category</Button>
          </Link>
        </div>

        <div className="p-5">
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ name: "", status: "",  }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <div className="grid grid-cols-4 gap-3 ">
                  <div>
                    <Field
                      label="Search By Name"
                      name="name"
                      type="text"
                      placeholder="Enter name"
                      component={InputField}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="status"
                      class="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
                    >
                      Status
                    </label>
                    <Field
                      name="status"
                      selectorOptions={statusOptions}
                      selectorLabelKey="label"
                      selectorValueKey="value"
                      placeholder="status"
                      component={SelectField}
                    />
                  </div>
               
                  <div className="flex items-end">
                    <button
                      type="submit"
                      // disabled={isSubmitting}
                      className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>


        <CategoryList filters={filters} />

        {/* <DataTable
          loadingMessage="Loading..."
          isLoading={isLoading}
          columns={columns}
          data={data}
          noDataMessage={<p className="my-5 py-5">No Data found!</p>}
        /> */}
      </Card>
    </>
  );
};

export default DomainCategories;
