import React, { useMemo } from "react";
import DataTable from "../dataTable/reactDataTable";
import EditUserModal from "./EditUserModal";
import { FaUser } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa6";
import { useGetUsersQuery } from "../../redux/queries";
import DeleteUserModal from "./DeleteUserModal";
import CircularPagination from "components/pagination/CircularPagination";
import { Slash } from "react-feather";

const UserList = ({ filters, setFilters }) => {
  const { data, isLoading } = useGetUsersQuery(filters);
  console.log("🚀 ~ UserList ~ data:", data);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        name: "User",
        maxWidth: "350px",
        selector: (row) => (
          <div className="flex items-center gap-2 py-2">
            {console.log("firstttttt", row)}
            <img
              className="h-10 w-10 rounded-full object-cover"
              src={row?.avatar || "/assets/images/images.png"}
              alt="Rounded avatar"
            />
            <div>
              <p className="font-semibold">  {row?.firstName} {row?.lastName}</p>
              <span
                className="truncate lowercase text-gray-700"
                title={row?.email}
              >
                {row?.email}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Role",
        selector: (row) => (
          <div className="flex items-center gap-1">
            <span className="rounded-full bg-red-400 p-1 text-white">
              {row.role === "member" ? <FaUser /> : <FaBriefcase />}
            </span>
            <span className="font-semibold capitalize">{row.role}</span>
          </div>
        ),
      },
      {
        name: "Status",
        selector: (row) => <span className="badge-success">Active</span>,
      },

      {
        name: "Actions",
        selector: (row) => (
          <div className="flex gap-3">
            <EditUserModal user={row}  />
            {row.role !== "admin" ?
              <DeleteUserModal user={row} id={row.id} /> :
              <Slash className="me-50" size={15} />
            }
          </div>
        ),
      },
    ];

    if (filters.role === "professional") {
      baseColumns.splice(1, 1, {
        name: "Domain",
        selector: (row) => (
          <div className="flex items-center gap-1">
            <span className="font-semibold capitalize">{row.domain}</span>
          </div>
        ),
      });
      baseColumns.splice(2, 0, {
        name: "designation",
        selector: (row) => (
          <div className="flex items-center gap-1">
            <span className="font-semibold capitalize">
              {row.designationToShow}
            </span>
          </div>
        ),
      });
    }
    if (filters.role === "member") {
      baseColumns.splice(2, 0, {
        name: "payment status",
        selector: (row) => (
          <div className="flex items-center gap-1">
            {row?.eligibleForServices ? (
              <span className="badge-success">Paid</span>
            ) : (
              <span className="badge-danger">UnPaid</span>
            )}
          </div>
        ),
      });
    }

    return baseColumns;
  }, [filters.role]);

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
  };

  return (
    <>
      <DataTable
        loadingMessage="User fetching..."
        isLoading={isLoading}
        columns={columns}
        data={data?.users}
        noDataMessage={<p className="my-5 py-5">No Users found!</p>}
      />
      <div className="d-flex justify-content-end">
        <CircularPagination
          currentPage={data?.currentPage}
          totalPages={data?.totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
};

export default UserList;
