import { Fragment, useMemo } from "react";
import ReactDataTable from "react-data-table-component";
// import CardLoader from "./card-loader";
import { ChevronDown } from "react-feather";
import useTableScrollEnd from "../../utils/useTableScrollEnd";
import { tableCellStyles } from "../../utils/tableStyles";
import { ClassNames } from "@emotion/react";

const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: "1rem",
      fontWeight: "600",
      borderBottom: "1px solid red",
      // paddingLeft: "0 8px",
    },
  },
};

function DataTable({
  isLoading = false,
  loadingMessage = "Loading Data...",
  noDataMessage = <p className="my-5 py-5">No Record found!</p>,
  columns,
  fixedColumns = false,
  ...props
}) {
  const { tableScrolling, handleTableScroll } = useTableScrollEnd();
  const memoizedColumns = useMemo(
    () =>
      fixedColumns
        ? columns.map((column) => {
            if (column.fixedCol) {
              return {
                ...column,
                cell: (row, idx, column, id) => {
                  const Wrapper = column.fixedCol ? "div" : Fragment;
                  return (
                    <Wrapper
                      {...(column.fixedCol
                        ? {
                            style: tableCellStyles,
                            className: tableScrolling
                              ? "table-cell-fixed-right"
                              : "",
                          }
                        : {})}
                    >
                      {column?.cell(row, idx, column, id)}
                    </Wrapper>
                  );
                },
              };
            } else {
              return column;
            }
          })
        : columns,
    [tableScrolling]
  );

  const WrapperDiv = fixedColumns ? "div" : Fragment;
  return (
    <WrapperDiv
      {...(fixedColumns
        ? { className: "overflow-x-auto", onScroll: handleTableScroll }
        : {})}
    >
      <ReactDataTable
        customStyles={tableCustomStyles}
        className="flex items-center justify-between pb-2 pt-4  text-start uppercase tracking-wide text-gray-700 sm:text-xs lg:text-xs"
        noDataComponent={noDataMessage}
        sortIcon={<ChevronDown size="16" className="ms-25" color="#000000" />}
        responsive={fixedColumns ? false : true}
        progressPending={isLoading}
        // progressComponent={
        //     <CardLoader loading={isLoading} message={loadingMessage} />
        // }
        columns={memoizedColumns}
        {...props}
      />
    </WrapperDiv>
  );
}

export default DataTable;
