import { ErrorMessage } from "../common/error-msg";
import InputField from "../fields/InputField";
import SelectField from "../fields/SelectField";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Edit } from "react-feather";
import { Input, Label, Col, FormGroup, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useGetCategoriesOptionsByDomainQuery, useUpdateUserMutation } from "../../redux/queries";
import { successToast } from "utils";
import { errorToast } from "utils";
import { yup } from "utils/constants";
import { domainsOptions } from "utils/data/options";
import { useModal } from "utils/useModal";
import { Eye } from "react-feather";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  firstName: yup.string().trim().label("First Name").required(),
  lastName: yup.string().trim().label("Last Name").required(),
  dateOfBirth: yup
    .date()
    .label("Date of Birth")
    .nullable("Please select a date!")
    .required()
    .max(new Date(), "Date of Birth cannot be in the future!"),
  phoneNumber: yup.string().trim().required("Phone Number is required"),
  role: yup.string().trim().label("Role").required(),
  domain: yup
    .string()
    .label("Domain")
    .when("role", {
      is: "professional",
      then: (schema) => schema.required(),
    }),
  designation: yup
    .string()
    .label("Designation")
    .when("role", {
      is: "professional",
      then: (schema) => schema.required(),
    }),
})

const EditUserModal = ({ user }) => {
  const [selectedDomain, setSelectedDomain] = useState(null);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    phoneNumber: "",
    role: "professional",
    domain: "",
    designation: "",
    email:""
  });
  const modal = useModal();
  const { data: designationsOptions, isLoading: designationsLoading } =
    useGetCategoriesOptionsByDomainQuery(selectedDomain, {
      skip: !selectedDomain,
    });

  const [mutate] = useUpdateUserMutation()

  const handleOpenModal = () => {
    console.log('puseruser', user)
    const { id, firstName,lastName,  dateOfBirth,email, phoneNumber, role, domain, designation } = user
    // const [firstName, lastName] = name.split(' ')

    const values = {
      id, firstName, lastName, phoneNumber, email,dateOfBirth, role, domain, designation
    }

    if (role === 'professional') {
      values.domain = domain
      values.designation = designation
    }
    setSelectedUser(values);
    setSelectedDomain(domain)
    console.log("🚀 ~ handleOpenModal ~ values:", values)
    modal.open();
  };

  const handleSubmit = async (values) => {
    try {
      const res = await mutate(values).unwrap()
      successToast(res.message)
      modal.close()
    } catch (error) {
      errorToast(error.message)

    }
  }
  const handleIconClick = (role, id) => {
    if (role === 'professional') {
      navigate(`/admin/professionals/view/${id}`);
    }
  };

  const handleClick = () => {
    const { role, id } = user;
    if (role === 'professional') {
      handleIconClick(role, id);
    } else {
      handleOpenModal();
    }
  };


  return (
    <>
      <span
        className="flex items-center gap-3"
        role="button"
        onClick={() => handleClick()

        }
      >
        {user.role === 'professional' ? (
          <Eye className="me-2" size={15} />
        ) : (
          <Edit className="me-2" size={15} />
        )}
      </span>
      <Modal isOpen={modal.show} toggle={modal.toggle}>
        <ModalHeader toggle={modal.toggle}>Edit User</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={selectedUser}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
                  <div className="px-3 py-3">
                    <Row>
                    <Col md={12}>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            type="text"
                            name="email"
                            value={values?.email}
                            readOnly
                            onChange={handleChange} 
                            onBlur={handleBlur} 
                          />
                          <ErrorMessage error={errors?.email} />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <InputField
                            label="First Name"
                            placeholder="Enter first name"
                            type="text"
                            name="firstName"
                            value={values?.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage error={errors.firstName} />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <InputField
                            label="Last Name"
                            placeholder="Enter last name"
                            type="text"
                            name="lastName"
                            value={values?.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage error={errors?.lastName} />
                        </FormGroup>
                      </Col>
                     
                      <Col md={12}>
                        <FormGroup>
                          <InputField
                            label="Phone Number"
                            placeholder="Enter phone number"
                            type="number"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage error={errors.phoneNumber} />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <InputField
                            label="Date of Birth"
                            placeholder="Select Date of Birth"
                            type="date"
                            name="dateOfBirth"
                            value={values.dateOfBirth}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage error={errors.dateOfBirth} />
                        </FormGroup>
                      </Col>
                      {/* <Col md="12" sm="12" className="mb-1">
                        <Label
                          className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                          for="role"
                        >
                          Role
                        </Label>
                        <div className="demo-inline-spacing mb-1 ml-3 flex gap-4">
                          <div className="form-check mt-0">
                            <Input
                              type="radio"
                              id="Customer"
                              name="role"
                              value="professional"
                              checked={values.role === "professional"}
                              onChange={handleChange}
                            />
                            <Label check htmlFor="Customer">
                              Professional
                            </Label>
                          </div>
                          <div className="form-check mt-0">
                            <Input
                              type="radio"
                              id="Dealer"
                              name="role"
                              value="member"
                              checked={values.role === "member"}
                              onChange={handleChange}
                            />
                            <Label check htmlFor="Dealer">
                              Member
                            </Label>
                          </div>
                        </div>
                      </Col> */}
                      {values.role === "professional" && (
                        <Col tag={Row} md="12" sm="12" className="mb-1">
                          <Col xs="12" md="12">
                            <Label
                              className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                              for="status"
                            >
                              Domain
                            </Label>
                            <Field
                              innerOnChange={setSelectedDomain}
                              name="domain"
                              component={SelectField}
                              placeholder="Select Domain"
                              selectorOptions={domainsOptions}
                              setSelectedDomain={setSelectedDomain}
                              selectorLabelKey="label"
                              selectorValueKey="value"
                            />
                          </Col>
                          <Col xs="12" md="12">
                            <Label
                              className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                              for="status"
                            >
                              Designation
                            </Label>
                            <Field
                              name="designation"
                              placeholder="Enter Designation"
                              component={SelectField}
                              selectorOptions={designationsOptions}
                              isLoading={designationsLoading}
                              selectorLabelKey="label"
                              selectorValueKey="value"
                            />
                            {/* <ErrorMessage error={errors?.designation} /> */}
                          </Col>
                        </Col>
                      )}
                    </Row>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditUserModal;
