import React from "react";
import DomainCategories from "components/Category/DomainCategories";

const LegalCategories = () => {


  return (
    <>
      <DomainCategories domain="legal" />
    </>
  );
};

export default LegalCategories;
