import { useParams } from "react-router-dom";
import linkedin from "../../../assets/img/professional/linkedin.png";
import twitter from "../../../assets/img/professional/twitter.png";
import instagram from "../../../assets/img/professional/instagram.png";
import fb from "../../../assets/img/professional/fb.png";
import { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { errorToast, successToast } from "utils";
import { useGetUserByIdQuery } from "../../../redux/queries";
import { useUpdateSocialLinksMutation } from "../../../redux/queries";

const AccountsSocialLinks = () => {
  const { id } = useParams();
  const { data, isLoading: fetching, refetch } = useGetUserByIdQuery(id);
  console.log('data................', data)
  const [updateSocialLinks, { isLoading: updating }] = useUpdateSocialLinksMutation();

  // Local state for form inputs
  const [socialLinks, setSocialLinks] = useState({
    facebook: "",
    linkedin: "",
    twitter: "",
    instagram: ""
  });

  useEffect(() => {
    if (data?.socialLinks) {
      setSocialLinks(data.socialLinks);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSocialLinks(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!id) {
      errorToast('User ID is missing.');
      return;
    }
console.log('submitttttid', id)
    try {
      const res = await updateSocialLinks({ id, socialLinks }).unwrap();
      console.log('res......', res)
      successToast(res.message);
      refetch();
    } catch (error) {
      errorToast(error.message);
    }
  };


  return (
    <div
      className="bg-white h-[350px] rounded-lg w-full mb-10 shadow-xl"
      style={{ boxShadow: "0px 0px 20px 0px #101E730F" }}
    >
      <h1 className="text-[20px] font-BeNational-700 py-6 px-2">
        Social Links
      </h1>
      <div className="p-3 sm:p-8 w-full">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-4">
            {['facebook', 'linkedin', 'twitter', 'instagram'].map((platform) => (
              <div className="col-span-6" key={platform}>
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor={platform}
                >
                  {platform.charAt(0).toUpperCase() + platform.slice(1)}
                </label>
                <div className="relative">
                  <img
                    src={platform === 'facebook' ? fb :
                      platform === 'linkedin' ? linkedin :
                        platform === 'twitter' ? twitter : instagram}
                    alt={platform}
                    className="absolute w-[26px] h-[26px] left-2 top-2"
                  />
                  <input
                    value={socialLinks[platform]}
                    onChange={handleChange}
                    type="text"
                    id={platform}
                    name={platform}
                    className="block w-full text-gray-700 py-2 px-14 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder={`Enter ${platform.charAt(0).toUpperCase() + platform.slice(1)} URL`}
                  />
                </div>
              </div>
            ))}

            <div className="col-span-12 flex justify-end">
              <Button
                type="submit"
                isLoading={updating}
                disabled={updating}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountsSocialLinks;
