import { yup } from "../constants";
export const usersValidationSchema = yup.object().shape({
  firstName: yup.string().trim().label("First Name").required(),
  lastName: yup.string().trim().label("Last Name").required(),
  dateOfBirth: yup
    .date()
    .label("Date of Birth")
    .nullable("Please select a date!")
    .required()
    .max(new Date(), "Date of Birth cannot be in the future!"),
  email: yup.string().trim().label("Email").email().required(),
  phoneNumber: yup.string().trim().label("Phone Number").required(),
  role: yup.string().trim().label("Role").required(),
  domain: yup.string().label("Domain").when("role", {
      is: "professional",
      then: (schema) => schema.required(),
    }),
  designation: yup
    .string()
    .label("Designation")
    .when("role", {
      is: "professional",
      then: (schema) => schema.required(),
    }),
});
