import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useNavigate } from "react-router-dom";
import { authInstance } from "services/api_Helpers";
import { useDispatch } from "react-redux";
import { handleLogin } from "../../redux/slices/authSlice";
import { successToast } from "utils";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Initial values
  const initialValues = {
    email: "",
    password: "",
    keepLoggedIn: false,
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    keepLoggedIn: Yup.boolean(),
  });
  // Handle form submission
  const handleSubmit = async (values) => {
    console.log(values);
    const response = await authInstance.post("/users/login/admin", values);
    const { data, message } = response.data;
    const { user, token } = data;
    dispatch(handleLogin({ user, token }));
    successToast(message);
    navigate("/admin/dashboard", { replace: true });
  };
  return (
    <div className="mb-16 mt-16 flex w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className=" w-full max-w-full flex-col items-center md:mt-[10vh] md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <div className=" flex items-center  gap-3">
          <div className=" w-full bg-gray-200 dark:bg-navy-700" />
          <div className="w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              {/* Email */}
              <div>
                <InputField
                  variant="auth"
                  label="Email*"
                  placeholder="mail@simmmple.com"
                  id="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message text-red-600"
                />
              </div>
              {/* Password */}
              <InputField
                variant="auth"
                label="Password*"
                placeholder="password"
                id="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                name="password"
                component="div"
                className="error-message text-red-600"
              />
              {/* Checkbox */}
              <div className="mb-4 mt-2 flex items-center justify-between px-2">
                <div className="flex items-center ">
                  <Checkbox
                    id="keepLoggedIn"
                    name="keepLoggedIn"
                    checked={values.keepLoggedIn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                    Keep me logged In
                  </p>
                </div>
                <a
                  className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                  href=" "
                >
                  Forgot Password?
                </a>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Sign In
              </button>
            </Form>
          )}
        </Formik>

        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <Link
            to="/auth/sign-up"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default SignIn;
