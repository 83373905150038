import toast from "react-hot-toast";

export const successToast = (msg) => toast.success(msg);
export const errorToast = (msg) => toast.error(msg ?? "Runtime Error!");
export const createFormData = (values = {}, options = {}) => {
  const { filter = [] } = options;
  const formData = new FormData();
  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key)) {
      let value = values[key];
      if (filter.includes(key)) {
        continue;
      }
      if (
        value instanceof File === false &&
        typeof value === "object" &&
        !Array.isArray(value)
      ) {
        value = JSON.stringify(values[key]);
      }

      if (Array.isArray(value) && value.length > 0) {
        console.log(value);
        value.forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, value);
      }
    }
  }
  return formData;
};

export const convertToFormData = (values) => {
  const formData = new FormData();
  for (const key in values) {
    if (Array.isArray(values[key])) {
      values[key].forEach((file) => {
        formData.append(key, file);
      });
    } else {
      formData.append(key, values[key]);
    }
  }
  return formData;
};
