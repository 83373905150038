import React from "react";
import DomainCategories from "components/Category/DomainCategories";
const AdministrativeCategories = () => {
  return (
    <>
      <DomainCategories domain="administrative" />
    </>
  );
};

export default AdministrativeCategories;
