export const usersInitialValues = {
  firstName: "",
  lastName: "",
  dateOfBirth: "2000/01/01",
  email: "",
  phoneNumber: "",
  location: "",
  role: "professional",
  domain: "",
  designation: "",
  shortDescription: "",
  professionalBackground: "",
  supportingDocuments: null,
};
