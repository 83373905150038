import React from "react";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import { useCreateCategoryMutation, useUpdateCategoryMutation } from "../../redux/queries";
import { successToast } from "utils";
import TextField from "components/fields/TextField";
import { ErrorMessage } from "components/common/error-msg";
import { useNavigate } from "react-router-dom";
import DropZone from "components/dropzone";
import { convertToFormData } from "utils";
import { errorToast } from "utils";
const AddCategoryForm = (props) => {
  const [mutate] = useCreateCategoryMutation();
  const navigate = useNavigate();
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = convertToFormData({ ...values, domain: props.domain });
      const res = await mutate(formData).unwrap();
      successToast(res.message);
      resetForm();
      navigate(`/admin/all-${props.domain}-categories`);
    } catch (error) {
      errorToast(error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").required(),
    nameFr: Yup.string().required("Name is required").required(),
    logo: Yup.mixed().required("Logo is required"),
    description: Yup.string().required("Description is required"),
    descriptionFr: Yup.string().required("Description is required"),
  });
   
  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          nameFr: "",
          logo: null,
          description: "",
          descriptionFr: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
              <div className="px-3 py-3">
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Field
                        label="Name"
                        placeholder="Enter your Name"
                        type="text"
                        name="name"
                        component={InputField}
                      />
                      <ErrorMessage error={errors.name} />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Field
                        label="Name (French)"
                        placeholder="Enter your name"
                        type="text"
                        name="nameFr"
                        component={InputField}
                      />
                      <ErrorMessage error={errors.nameFr} />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Field
                        label="Description"
                        placeholder="Enter Description "
                        type="text"
                        name="description"
                        rows={10}
                        component={TextField}
                      />

                      <ErrorMessage error={errors.description} />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Field
                        label="Description (French)"
                        placeholder="Enter Description "
                        type="text"
                        name="descriptionFr"
                        rows={10}
                        component={TextField}
                      />

                      <ErrorMessage error={errors.descriptionFr} />
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup>
                      <DropZone
                        setFieldValue={setFieldValue}
                        errors={errors?.logo}
                        values={values}
                        label="Add a Logo"
                        name="logo"
                        multiple={false}
                        accept={{
                          "image/*": [".png", ".jpg", ".jpeg",".svg", ".webp"],
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end ">
                  <button
                    type="submit"
                    className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddCategoryForm;
