import Card from "components/card";
import React, { useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Calendar } from "react-calendar";
import { Field, Form, Formik } from "formik";
import InputField from "components/fields/InputField";
import { Button } from "components/ui-elements/Button";
import UserList from "components/UserList";

const AllAdministrativeProfessionals = () => {
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [filters, setFilters] = useState({ role: "professional", domain: "administrative" })

  const handleSearch = (values) => {
    console.log("handleSearch", values);
    try {
      //   dispatch(getUserList(values));
    } catch (error) {
      console.log("error", error);
    }
  };


  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  return (
    <>
      <Card extra={"mt-4 !z-5 overflow-hidden"}>
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            All Administrative Professionals
          </h4>
        </div>
        <div className="px-3">
          <Formik
            //   toggle={toggle}
            initialValues={{ name: "", status: "", date: new Date() }}
          //   onSubmit={handleSearch}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="grid grid-cols-3 gap-3 pt-5">
                  <div>
                    <Field
                      label="Search By Name"
                      name="name"
                      type="text"
                      placeholder="Enter name"
                      component={InputField}
                    />
                  </div>
                  <div>
                    <label
                      className="form-label ml-3 block text-sm font-bold text-navy-700 dark:text-white"
                      for="basic-default-fullname"
                    >
                      Search By Joining Date
                    </label>
                    <input
                      type="text"
                      //   value={values?.date.toDateString()}
                      readOnly
                      onClick={toggleCalendar}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                    />
                    {isCalendarVisible && (
                      <Card extra="flex w-[300px] z-20 flex-col px-3 py-3 !absolute">
                        <Calendar
                          onChange={(e) => {
                            toggleCalendar();
                            setFieldValue("date", e);
                          }}
                          value={values?.date}
                          prevLabel={
                            <MdChevronLeft className="ml-1 h-6 w-6 " />
                          }
                          nextLabel={
                            <MdChevronRight className="ml-1 h-6 w-6 " />
                          }
                          view={"month"}
                        />
                      </Card>
                    )}
                  </div>
                  <div className="flex items-end">
                    <Button> Search </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <UserList filters={filters} />
      </Card>
    </>
  );
};

export default AllAdministrativeProfessionals;
