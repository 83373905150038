import React from "react";
import DomainCategories from "components/Category/DomainCategories";

const NotaryCategories = () => {
  return (
    <>
      <DomainCategories domain="notary" />
    </>
  );
};

export default NotaryCategories;
