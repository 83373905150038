import React from "react";
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import {
  FaBriefcase,
  FaList,
  FaLocationArrow,
  FaQuestionCircle,
  FaUsers,
} from "react-icons/fa";

// Auth Imports
import SignIn from "./views/auth/SignIn";

// Icon Imports
import { MdHome, MdPerson, MdLock } from "react-icons/md";
import { FaHeartbeat } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { FaGavel } from "react-icons/fa6";
import FAQs from "views/admin/Faqs/Faqs";
import SignUp from "views/auth/SignUp";
import { HiUsers } from "react-icons/hi";
import { RiUserSharedFill } from "react-icons/ri";
import { FaFileSignature } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";

// local imports
import AllUsers from "views/admin/Users/allUsers";
import AddUser from "views/admin/Users/addUser";
import Professionals from "views/admin/Users/Professionals";
import Members from "views/admin/Users/Members";
import HealthCategories from "views/admin/health/AllCategories";
import AllHealthProfessionals from "views/admin/health/AllProfessionals";
import AddHealthCategory from "views/admin/health/AddCategory";
import LegalCategories from "views/admin/legal/AllCategories";
import AllLegalProfessionals from "views/admin/legal/AllProfessionals";
import AddLegalCategory from "views/admin/legal/AddCategory";
import NotaryCategories from "views/admin/notary/AllCategories";
import AllNotaryProfessionals from "views/admin/notary/AllProfessionals";
import AddNotaryCategory from "views/admin/notary/AddCategory";
import AdministrativeCategories from "views/admin/administrative/AllCategories";
import AllAdministrativeProfessionals from "views/admin/administrative/AllProfessionals";
import AddAdministrativeCategory from "views/admin/administrative/AddCategory";
import AddLocation from "views/admin/location/AddLocation";
import AllLocations from "views/admin/location/AllLocations";
import ProtectedRoutes from "components/common/protected-route";
import ViewProfessional from "views/admin/manageProfessioanl/viewProfessional";
const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: (
      <ProtectedRoutes>
        <MainDashboard />
      </ProtectedRoutes>
    ),
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    header: true,
    name: "Users",
    layout: "/admin",
    icon: <HiUsers className="h-6 w-6" />,
    childrens: [
      {
        name: "All Users",
        layout: "/admin",
        path: "all-users",
        icon: <FaUsers className="h-5 w-4" />,
        component: <ProtectedRoutes><AllUsers /></ProtectedRoutes>,
      },
      {
        name: "Add User",
        layout: "/admin",
        path: "add-user",
        icon: <MdAddCircle className="h-5 w-4" />,
        component: (
          <ProtectedRoutes>
            <AddUser />
          </ProtectedRoutes>
        ),
      },
      {
        name: "Members",
        layout: "/admin",
        path: "members",
        icon: <RiUserSharedFill className="h-5 w-4" />,
        component: <ProtectedRoutes><Members /></ProtectedRoutes>,
      },
      {
        name: "Professionals",
        layout: "/admin",
        path: "professionals",
        icon: <FaBriefcase className="h-5 w-4" />,

        component: <ProtectedRoutes><Professionals /></ProtectedRoutes>,
      },
      {
        name: "Professionals",
        layout: "/admin",
        path: "professionals/view/:id",
        visible:false,
        icon: <FaBriefcase className="h-5 w-4" />,
        component: <ProtectedRoutes><ViewProfessional /></ProtectedRoutes>,
      },
   
    ],
  },
  {
    header: true,
    name: "Location",
    icon: <FaLocationArrow className="h-6 w-6" />,
    layout: "/admin",
    childrens: [
      {
        name: "Add Location",
        layout: "/admin",
        path: "add-location",
        icon: <MdAddCircle className="h-4 w-4" />,
        component: <ProtectedRoutes><AddLocation /></ProtectedRoutes>,
      },
      {
        name: "All Locations",
        layout: "/admin",
        path: "location-list",
        icon: <FaList className="h-4 w-4" />,
        component:<ProtectedRoutes> <AllLocations /></ProtectedRoutes>,
      },
    ],
  },
  {
    header: true,
    name: "Health",
    icon: <FaHeartbeat className="h-6 w-6" />,
    layout: "/admin",
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-health-categories",
        icon: <FaList className="h-4 w-4" />,
        component:<ProtectedRoutes> <HealthCategories /></ProtectedRoutes>,
      },
      {
        name: "All Services",
        layout: "/admin",
        path: "all-health-services",
        icon: <FaBriefcase className="h-4 w-4" />,
        component:<ProtectedRoutes> <AllHealthProfessionals /></ProtectedRoutes>,
      },
      {
        name: "Add Category",
        layout: "/admin",
        path: "add-health-category",
        icon: <MdAddCircle className="h-4 w-4" />,
        component:<ProtectedRoutes> <AddHealthCategory /></ProtectedRoutes>,
      },
    ],
  },
  {
    header: true,
    name: "Legal",
    icon: <FaGavel className="h-5 w-6" />,
    layout: "/admin",
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-legal-categories",
        icon: <FaList className="h-4 w-6" />,
        component:<ProtectedRoutes> <LegalCategories /></ProtectedRoutes>,
      },
      {
        name: "All Services",
        layout: "/admin",
        path: "all-legal-services",
        icon: <FaBriefcase className="h-4 w-6" />,
        component:<ProtectedRoutes> <AllLegalProfessionals /></ProtectedRoutes>,
      },
      {
        name: "Add Categories",
        layout: "/admin",
        path: "add-legal-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component:<ProtectedRoutes> <AddLegalCategory /></ProtectedRoutes>,
      },
    ],
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock />,
    component: <SignIn className="h-6 w-6" />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock />,
    component: <SignUp className="h-6 w-6" />,
  },
  {
    header: true,
    name: "Notary",
    layout: "/admin",
    icon: <FaFileSignature className="h-5 w-6" />,
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-notary-categories",
        icon: <FaList className="h-4 w-6" />,
        component:<ProtectedRoutes> <NotaryCategories /></ProtectedRoutes>,
      },
      {
        name: "All Services",
        layout: "/admin",
        path: "all-notary-services",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <ProtectedRoutes><AllNotaryProfessionals /></ProtectedRoutes>,
      },
      {
        name: "Add Category",
        layout: "/admin",
        path: "add-notary-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component:<ProtectedRoutes> <AddNotaryCategory /></ProtectedRoutes>,
      },
    ],
  },
  {
    header: true,
    name: "Administrative",
    layout: "/admin",
    icon: <FaUserTie className="h-5 w-6" />,
    childrens: [
      {
        name: "All Categories",
        layout: "/admin",
        path: "all-administrative-categories",
        icon: <FaList className="h-4 w-6" />,
        component: <AdministrativeCategories />,
      },
      {
        name: "All Services",
        layout: "/admin",
        path: "all-administrative-services",
        icon: <FaBriefcase className="h-4 w-6" />,
        component: <AllAdministrativeProfessionals />,
      },
      {
        name: "Add Category",
        layout: "/admin",
        path: "add-administrative-category",
        icon: <MdAddCircle className="h-4 w-6" />,
        component: <AddAdministrativeCategory />,
      },
    ],
  },

  //!! useful components exits in this routes
  // {
  //   header: true,
  //   name: "Notary",
  //   layout: "/admin",
  //   path: "notary",
  //   icon: <FaBlog  />,
  //   childrens: [
  //     {
  //       name: "All Categories",
  //       layout: "/admin",
  //       path: "all-categories",
  //       icon: <CiCircleList  />,
  //       component: <BlogCategories />,
  //     },
  //     {
  //       name: "All Services",
  //       layout: "/admin",
  //       path: "all-professionals",
  //       icon: <CiCircleList  />,
  //       component: <Blogs />,
  //     },
  //     {
  //       name: "Add Category",
  //       layout: "/admin",
  //       path: "add-category",
  //       icon: <IoAddOutline  />,
  //       component: <AddBlog />,
  //     },
  //   ],
  // },

  // {
  //   name: "FAQs",
  //   layout: "/admin",
  //   path: "faqs",
  //   icon: <FaQuestionCircle className="h-5 w-6" />,
  //   component:<ProtectedRoutes> <FAQs /></ProtectedRoutes>,
  // },
];
export default routes;
