import React from "react";
import Card from "components/card";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { successToast, errorToast } from "utils";
import * as Yup from "yup";
import { ErrorMessage } from "components/common/error-msg";
import InputField from "../../../components/fields/InputField";
import TextField from "../../../components/fields/TextField";
import { useCreateLocationMutation } from "../../../redux/queries";
const AddLocation = () => {
  const [mutate] = useCreateLocationMutation();
  const navigate = useNavigate();
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const res = await mutate(values).unwrap();
      successToast(res.message);
      resetForm();
      navigate(`/admin/location-list`)
    } catch (error) {
      errorToast(error.message)
    }

  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").required()
  });
  return (
    <Card extra="flex w-full h-full mt-4 flex-col">
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Add New Location
        </h4>
      </div>
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
        <div>
          <Formik
            initialValues={{
              name: "",
              nameFr: "",
              description: "",
              descriptionFr: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
                  <div className="px-3 py-3">
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Field
                            label="Name"
                            placeholder="Enter your Name"
                            type="text"
                            name="name"
                            component={InputField}
                          />
                          <ErrorMessage error={errors.name} />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Field
                            label="Name (French)"
                            placeholder="Enter your name"
                            type="text"
                            name="nameFr"
                            component={InputField}
                          />

                          <ErrorMessage error={errors.nameFr} />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Field
                            label="Description"
                            placeholder="Enter Description "
                            type="text"
                            name="description"
                            rows={10}
                            component={TextField}
                          />

                          <ErrorMessage error={errors.description} />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Field
                            label="Description (French)"
                            placeholder="Enter Description "
                            type="text"
                            name="descriptionFr"
                            rows={10}
                            component={TextField}
                          />

                          <ErrorMessage error={errors.descriptionFr} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-end ">
                      <button
                        type="submit"
                        className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Card>
  );
};

export default AddLocation;
