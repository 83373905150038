import { useState } from "react";
import { useSelector } from "react-redux";
import AccountsPersonalInfo from "./accountPersonalInfo";
import AccountsSocialLinks from "./accountSocialLinks";
import EducationalBackGround from "./professionalEducation";
import ExperienceBackGround from "./ProfessionalExperience";

const ViewProfessional = () => {
  const [activeTab, setActiveTab] = useState("personalInfo");
  const { user } = useSelector((state) => state.auth);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Horizontal tab header */}
      <div className="shadow-lg p-4 rounded mt-6 bg-white">
        <div className="flex justify-around gap-4">
          <button
            className={`cursor-pointer p-2 border-b-2 ${
              activeTab === "personalInfo"
                ? "font-semibold border-red-600 text-black"
                : "font-normal border-transparent text-black"
            }`}
            onClick={() => handleTabClick("personalInfo")}
          >
            Personal Information
          </button>
          <button
            className={`cursor-pointer p-2 border-b-2 ${
              activeTab === "socialLinks"
                ? "font-semibold border-red-600 text-black"
                : "font-normal border-transparent text-black"
            }`}
            onClick={() => handleTabClick("socialLinks")}
          >
            Social Links
          </button>
          <button
            className={`cursor-pointer p-2 border-b-2 ${
              activeTab === "educational"
                ? "font-semibold border-red-600 text-black"
                : "font-normal border-transparent text-black"
            }`}
            onClick={() => handleTabClick("educational")}
          >
            Educational
          </button>
          <button
            className={`cursor-pointer p-2 border-b-2 ${
              activeTab === "experience"
                ? "font-semibold border-red-600 text-black"
                : "font-normal border-transparent text-black"
            }`}
            onClick={() => handleTabClick("experience")}
          >
            Experience
          </button>
        </div>
      </div>

      {/* Main content area */}
      <div className="flex-1 p-4">
        {activeTab === "personalInfo" && <AccountsPersonalInfo />}
        {activeTab === "socialLinks" && <AccountsSocialLinks />}
        {activeTab === "educational" && <EducationalBackGround />}
        {activeTab === "experience" && <ExperienceBackGround/>}
      </div>
    </div>
  );
};

export default ViewProfessional;
