import React from "react";
import { useDropzone } from "react-dropzone";
import { FiDownloadCloud } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { Card, Col, Label, Row } from "reactstrap";

const DropZone = (props) => {
  const {
    setFieldValue,
    values,
    label,
    name,
    multiple,
    accept,
    errors,
    children,
  } = props;
  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple === false || multiple === true ? multiple : true,
    accept: accept
      ? {
          ...accept,
        }
      : undefined,
    onDrop: (uploadedImages) => {
      handleImageChange(uploadedImages, setFieldValue);
    },
  });

  const handleImageChange = (uploadedImages, setFieldValue) => {
    const files = uploadedImages;
    const result = Object.keys(accept).map((key) => accept[key]);
    const allowedExtensions = result.flat(1);
    console.log(
      "🚀 ~ handleImageChange ~ allowedExtensions:",
      allowedExtensions
    );
    const maxSize = 75 * 1024 * 1024;
    const validImages = Array.from(files)
      .filter((file) => {
        const extension = `.${file.path.split(".").at(-1)}`;
        console.log(
          "🚀 ~ .filter ~ allowedExtensions.includes(extension);:",
          allowedExtensions.includes(extension)
        );
        return allowedExtensions.includes(extension);
      })
      .filter((file) => file.size <= maxSize);
    if (validImages.length !== files.length) {
      alert(
        "Invalid file types or sizes detected. Please select images only (JPEG, PNG, GIF) and under 75 MB each."
      );
      return;
    }
    if (name) {
      setFieldValue(
        name,
        multiple
          ? Array.isArray(values[`${name}`])
            ? [...values[`${name}`], ...validImages]
            : validImages
          : validImages
      );
    } else {
      setFieldValue(
        "images",
        multiple
          ? Array.isArray(values[`${name}`])
            ? [...values[`${name}`], ...validImages]
            : validImages
          : validImages
      );
    }
  };

  const renderUploadedImages = () => {
    return (
      <div
        className="mt-4 overflow-auto"
        style={{ display: "flex", marginBottom: "15px", width: "100%" }}
      >
        {Array.isArray(values[`${name}`]) && name
          ? values[`${name}`] &&
            values[`${name}`]?.map((image, index) => (
              <div
                key={index}
                className="uploaded-image"
                style={{ marginRight: "20px" }}
              >
                <div>
                  <img
                    src={
                      typeof image == "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    onError={(e) =>
                      (e.currentTarget.src = "/assets/images/doc-dummy.png")
                    }
                    alt={`Uploaded ${index}`}
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "12px",
                      marginBottom: "20px",
                    }}
                  />
                </div>
                <div
                  className="flex justify-center "
                  style={{ marginTop: "-10px" }}
                >
                  <IoMdClose
                    onClick={(e) => {
                      e.preventDefault();
                      if (typeof image == "string") {
                        setFieldValue(
                          "deletedImages",
                          values?.deletedImages
                            ? [...values?.deletedImages, image]
                            : [image]
                        );
                      }
                      const newImages = [...values[`${name}`]];
                      newImages.splice(index, 1);
                      setFieldValue(name, newImages);
                    }}
                    color="red"
                    size={25}
                  />
                </div>
              </div>
            ))
          : values[`${name}`]?.map((image, i) => (
              <div
                key={i}
                className="uploaded-image"
                style={{ marginRight: "20px" }}
              >
                <img
                  src={
                    typeof image == "string"
                      ? image
                      : URL.createObjectURL(image)
                  }
                  alt={`Uploaded ${i}`}
                  style={{
                    width: "200px",
                    margin: ":10px 0px",
                    height: "auto",
                  }}
                />
              </div>
            ))}

        {!Array.isArray(values[`${name}`]) && values[name]?.length > 0 && (
          <img
            src={values[`${name}`]}
            alt={`Uploaded`}
            style={{
              width: "200px",
              height: "200px",
              objectFit: "cover",
              borderRadius: "12px",
              marginBottom: "20px",
            }}
          />
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Col md="12" sm="12">
        <Label
          className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
          for={label}
        >
          {label}
        </Label>
        <Row className="import-component">
          <Col sm="12">
            <Card>
              <div>
                <Row>
                  <Col sm="12">
                    <div
                      {...getRootProps({
                        className: "dropzone",
                      })}
                      style={{
                        height: "352px",
                        border: `${
                          errors && errors ? "1px solid #ea5455" : ""
                        }`,
                      }}
                    >
                      <input {...getInputProps()} />
                      <div className="d-flex align-items-center justify-content-center flex-column pt-28">
                        <FiDownloadCloud size={64} />
                        <h5>Drop Images here or click to upload</h5>
                        <p className="text-secondary">
                          Drop images here or click{" "}
                          <a href="/" onClick={(e) => e.preventDefault()}>
                            browse
                          </a>{" "}
                          thorough your machine
                        </p>
                      </div>
                    </div>

                    {errors ? (
                      <p className="text-danger" style={{ marginTop: "7px" }}>
                        {errors}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <Row></Row>
      </Col>
      {children ? (
        <>{children}</>
      ) : (
        <div className="uploaded-images-container" style={{ width: "100%" }}>
          {renderUploadedImages()}
        </div>
      )}
    </React.Fragment>
  );
};

export default DropZone;
