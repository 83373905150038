import { ServicsTags } from "../tags";
import { injectApiEndpoints } from "../api";
import { transformResponse } from "./helpers";

const url = "/services";
const appendUrl = (segment) => `${url}/${segment}`;

const usersApis = injectApiEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: (filters = {}) => {
        console.log("filters", filters);
        let query = ''
        if (filters.designation) query += `designation=${filters.designation}&`
        if (filters.domain) query += `domain=${filters.domain}&`
        if (filters.status) query += `status=${filters.status}&`
        if (filters.limit) query += `limit=${filters.limit}&`
        return {
          url: `${url}?${query}`,
          method: "get",
        }
      },
      transformResponse: transformResponse,
      providesTags: [ServicsTags],
    }),
    deleteService: builder.mutation({
      query: (id) => ({
        url: appendUrl(`delete/${id}`),
        method: "delete",
      }),
      invalidatesTags: [ServicsTags],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useDeleteServiceMutation,
} = usersApis;
