import { LocationTags } from "../tags";
import { injectApiEndpoints } from "../api";
import { transformResponse } from "./helpers";

const url = "/location";
const appendUrl = (segment) => `${url}/${segment}`;

const LocationsApis = injectApiEndpoints({
  endpoints: (builder) => ({
    createLocation: builder.mutation({
      query: (body) => ({
        url,
        method: "post",
        body,
      }),
      invalidatesTags: [LocationTags],
    }),
    getlocations: builder.query({
      query: (filters = {}) => {
        let query = "";
        if (filters.domain) query = `domain=${filters.domain}&`;
        return {
          url: `${url}?${query}`,
          method: "get",
        };
      },
      transformResponse: transformResponse,
      providesTags: [LocationTags],
    }),
    updateLocation: builder.mutation({
      query: ({ id, ...body }) => ({
        url: appendUrl(id),
        method: "put",
        body,
      }),
      invalidatesTags: [LocationTags],
    }),
    deleteLocation: builder.mutation({
      query: (id) => ({
        url: appendUrl(id),
        method: "delete",
      }),
      invalidatesTags: [LocationTags],
    }),

    getLocationsOptions: builder.query({
      query: () => ({
        url: appendUrl("all/options"),
        method: "get",
      }),
      transformResponse,
    }),
  }),
});
export const {
  useCreateLocationMutation,
  useGetlocationsQuery,
  // useUpdateLocationMutation,
  useDeleteLocationMutation,
  useGetLocationsOptionsQuery,
} = LocationsApis;
