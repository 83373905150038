import "react-international-phone/style.css";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";

import { useUpdateExperienceDetailByIdMutation, useDeleteExperienceBackGroundMutation, useGetProExperienceQuery, useAddExperienceBackGroundMutation } from "../../../redux/queries";
import { Button, Input, Label } from "reactstrap";
import { successToast } from "utils";
import { errorToast } from "utils";
import { useParams } from "react-router-dom";

const defaultValues = {
    organization: "",
    designation: "",
    startDate: "",
    endDate: "",
    description: "",
};

const ExperienceBackGround = () => {
    const { id } = useParams();

    const [addExperience] = useAddExperienceBackGroundMutation();
    const [deleteExperience] = useDeleteExperienceBackGroundMutation();
    const { data, refetch } = useGetProExperienceQuery(id);
    const [updateExperience] = useUpdateExperienceDetailByIdMutation();


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [editData, setEditData] = useState(defaultValues);

    const openModal = (data = defaultValues) => {
        setEditData(data);
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteId(null);
        setIsDeleteModalOpen(false);
    };

    const handleAddOrUpdate = async (event) => {
        event.preventDefault();
        try {
            if (editData._id) {
                // Update experience
                const res = await updateExperience({ id: editData._id, body: editData }).unwrap();
                successToast(res.message);
            } else {
                // Add new experience
                const res = await addExperience({ id, body: editData }).unwrap();
                successToast(res.message);
            }
            closeModal();
            refetch();
        } catch (error) {
            errorToast(error.message);
        }
    };
    const handleDelete = async () => {
        try {
            const res = await deleteExperience(deleteId).unwrap();
            successToast(res.message);
            closeDeleteModal();
            refetch();
        } catch (error) {
            errorToast(error.message);
        }
    };

    return (
        <>
            <div className="bg-white rounded-lg w-full shadow-xl flex flex-col gap-5 px-5 py-10" style={{ boxShadow: "0px 0px 20px 0px #101E730F" }}>
                <div className="flex justify-between">
                    <h1 className="text-[20px] font-BeNational-500">Experience Information</h1>
                    <IoMdAdd size={20} onClick={() => openModal()} className="cursor-pointer" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 p-4">
                    {data?.length > 0 && data.map((item) => (
                        <div className="w-full bg-white shadow-lg p-6 rounded-xl border border-gray-200" key={item._id}>
                            <div className="flex flex-col gap-4">
                                <div className="flex justify-between items-start">
                                    <div>
                                        <h1 className="text-xl font-bold text-gray-800 mb-1">{item.organization}</h1>
                                        <h2 className="text-lg font-semibold text-gray-600">{item.designation}</h2>
                                    </div>
                                    <div className="flex gap-3 text-gray-500">
                                        <MdEdit size={24} className="cursor-pointer hover:text-gray-700 transition-colors" onClick={() => openModal(item)} />
                                        <RiDeleteBin6Line size={22} className="cursor-pointer hover:text-red-600 transition-colors" onClick={() => openDeleteModal(item._id)} />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <h3 className="text-sm font-medium text-gray-500">
                                        {item.startDate} <span className="text-gray-400">—</span> {item.endDate}
                                    </h3>
                                </div>
                                <div className="overflow-hidden bg-gray-100 p-3 rounded-md">
                                    <h1 className="text-muted-foreground font-BeNational-500 max-h-52 overflow-y-auto w-full custom-scrollbar">
                                        Description
                                        {item.description}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {data?.length === 0 && <p className="text-center py-10">No Experience Details</p>}
                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-[1140px] relative">
                            <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-900" onClick={closeModal}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h1 className="text-[20px] font-BeNational-700 mb-5">
                                {editData._id ? 'Edit Experience Details' : 'Add Experience Details'}
                            </h1>
                            <form onSubmit={handleAddOrUpdate} className="grid grid-cols-12 gap-5 items-center">
                                <div className="col-span-6">
                                    <Label htmlFor="designation" required>Designation</Label>
                                    <Input
                                        type="text"
                                        id="designation"
                                        name="designation"
                                        value={editData.designation || ""}
                                        onChange={(e) => setEditData({ ...editData, designation: e.target.value })}
                                        placeholder="Your Designation"
                                    />
                                </div>
                                <div className="col-span-6">
                                    <Label htmlFor="organization" required>Organization</Label>
                                    <Input
                                        type="text"
                                        id="organization"
                                        name="organization"
                                        value={editData.organization || ""}
                                        onChange={(e) => setEditData({ ...editData, organization: e.target.value })}
                                        placeholder="Your Organization"
                                    />
                                </div>
                                <div className="col-span-6">
                                    <Label htmlFor="startDate" required>From</Label>
                                    <Input
                                        type="date"
                                        id="startDate"
                                        name="startDate"
                                        value={editData.startDate || ""}
                                        onChange={(e) => setEditData({ ...editData, startDate: e.target.value })}
                                    />
                                </div>
                                <div className="col-span-6">
                                    <Label htmlFor="endDate" required>To</Label>
                                    <Input
                                        type="date"
                                        id="endDate"
                                        name="endDate"
                                        value={editData.endDate || ""}
                                        onChange={(e) => setEditData({ ...editData, endDate: e.target.value })}
                                    />
                                </div>
                                <div className="col-span-12">
                                    <Label htmlFor="description" required>Description</Label>
                                    <Input
                                        type="textarea"
                                        id="description"
                                        name="description"
                                        rows="6"
                                        value={editData.description || ""}
                                        onChange={(e) => setEditData({ ...editData, description: e.target.value })}
                                        placeholder="Your Description"
                                    />
                                </div>
                                <div className="col-span-12 flex justify-end">
                                    <Button type="submit" isLoading={false} disabled={false}>
                                        {editData._id ? 'Update' : 'Save'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                {isDeleteModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-[30%] relative">
                            <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-900" onClick={closeDeleteModal}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h1 className="text-[20px] font-BeNational-700 mb-5">Delete Experience Details</h1>
                            <div className="grid grid-cols-12 gap-5 items-center">
                                <div className="col-span-12 font-BeNational-300">
                                    <h1>Are you sure you want to delete?</h1>
                                </div>
                                <div className="col-span-12">
                                    <Button
                                        type="button"
                                        isLoading={false}
                                        disabled={false}
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ExperienceBackGround;
