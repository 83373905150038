import Card from "components/card";
import InputField from "components/fields/InputField";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { usersInitialValues } from "utils/initial-values";
import { usersValidationSchema } from "utils/validation-schema";
import { ErrorMessage } from "components/common/error-msg";
import SelectField from "components/fields/SelectField";
import { domainsOptions } from "utils/data/options";
import {
  useCreateUserMutation,
  useGetLocationsOptionsQuery,
  useGetCategoriesOptionsByDomainQuery,
} from "../../../redux/queries";
import { useNavigate } from "react-router-dom";
import TextField from "components/fields/TextField";
import ReactQuill from "react-quill";
import DropZone from "components/dropzone";
import { successToast } from "utils";
import { errorToast } from "utils";
import { createFormData } from "utils";
import { useState } from "react";

const AddUser = () => {
  const [selectedDomain, setSelectedDomain] = useState(null);
  const navigate = useNavigate();
  const { data: locationOptions = [], isLoading: locationsLoading } =
    useGetLocationsOptionsQuery();

  const { data: designationsOptions, isLoading: designationsLoading } =
    useGetCategoriesOptionsByDomainQuery(selectedDomain, {
      skip: !selectedDomain,
    });
    
  const [mutate] = useCreateUserMutation();
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = createFormData(values);
      const res = await mutate(formData).unwrap();
      successToast(res.message);
      resetForm(usersInitialValues);
      navigate("/admin/all-users");
    } catch (error) {
      errorToast(error.message);
    }
  };

return (
    <Card extra="flex w-full h-full mt-4 flex-col">
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Add User
        </h4>
      </div>
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
        <Formik
          initialValues={usersInitialValues}
          validationSchema={usersValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
                <div className="px-3 py-3">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <InputField
                          label="First Name"
                          placeholder="Enter first name"
                          type="text"
                          name="firstName"
                          value={values?.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage error={errors.firstName} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <InputField
                          label="Last Name"
                          placeholder="Enter last name"
                          type="text"
                          name="lastName"
                          value={values?.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage error={errors?.lastName} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <InputField
                          label="Email"
                          placeholder="Enter email"
                          type="text"
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage error={errors?.email} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <InputField
                          label="Phone Number"
                          placeholder="Enter phone number"
                          type="number"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage error={errors?.phoneNumber} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <InputField
                          label="Date of Birth"
                          placeholder="Select Date of Birth"
                          type="date"
                          name="dateOfBirth"
                          value={values.dateOfBirth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage error={errors.dateOfBirth} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <div>
                        <label
                          htmlFor="status"
                          className="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
                        >
                          Location
                        </label>
                        <Field
                          name="location"
                          isLoading={locationsLoading}
                          selectorOptions={locationOptions}
                          selectorLabelKey="label"
                          selectorValueKey="value"
                          placeholder="location"
                          component={SelectField}
                        />
                      </div>
                    </Col>
                    <Col md="12" sm="12" className="mb-1">
                      <Label
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                        for="role"
                      >
                        Role
                      </Label>
                      <div className="demo-inline-spacing mb-1 ml-3 flex gap-4">
                        <div className="form-check mt-0">
                          <Input
                            type="radio"
                            id="Customer"
                            name="role"
                            value="professional"
                            checked={values.role === "professional"}
                            onChange={handleChange}
                          />
                          <Label check htmlFor="Customer">
                            Professional
                          </Label>
                        </div>
                        <div className="form-check mt-0">
                          <Input
                            type="radio"
                            id="Dealer"
                            name="role"
                            value="member"
                            checked={values.role === "member"}
                            onChange={handleChange}
                          />
                          <Label check htmlFor="Dealer">
                            Member
                          </Label>
                        </div>
                      </div>
                    </Col>
                    {values.role === "professional" && (
                      <Col tag={Row} md="12" sm="12" className="mb-1">
                        <Col xs="12" md="6">
                          <Label
                            className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                            for="status"
                          >
                            Domain
                          </Label>
                          <Field
                            innerOnChange={setSelectedDomain}
                            name="domain"
                            component={SelectField}
                            placeholder="Select Domain"
                            selectorOptions={domainsOptions}
                            setSelectedDomain={setSelectedDomain}
                            selectorLabelKey="label"
                            selectorValueKey="value"
                          />
                        </Col>
                        <Col xs="12" md="6">
                          <Label
                            className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                            for="status"
                          >
                            Designation
                          </Label>
                          <Field
                            name="designation"
                            placeholder="Enter Designation"
                            component={SelectField}
                            selectorOptions={designationsOptions}
                            isLoading={designationsLoading}
                            selectorLabelKey="label"
                            selectorValueKey="value"
                          />
                        </Col>
                        <Col xs="12" md="12">
                          <Field
                            label="Short Description"
                            name="shortDescription"
                            rows={5}
                            component={TextField}
                            placeholder="Enter Designation"
                          />
                          <ErrorMessage error={errors.shortDescription} />
                        </Col>
                        <Col xs="12" md="12" className="mt-3">
                          <label
                            htmlFor="status"
                            className="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
                          >
                            Professional Background
                          </label>
                          <ReactQuill
                            name="professionalBackground"
                            theme="snow"
                            className="quil-editor"
                            onChange={(e) =>
                              setFieldValue("professionalBackground", e)
                            }
                            value={values.professionalBackground}
                          />
                          <ErrorMessage error={errors.professionalBackground} />
                        </Col>
                        <Col xs="12" md="12">
                          <DropZone
                            setFieldValue={setFieldValue}
                            errors={errors?.docs}
                            values={values}
                            label="Attach supporting documents (if any)"
                            name="supportingDocuments"
                            multiple
                            accept={{
                              "application/pdf": [".pdf"],
                              "image/*": [".png", ".jpg", ".svg", ".webp"],
                              "application/msword": [".docx"],
                            }}
                          />
                        </Col>
                      </Col>
                    )}
                  </Row>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default AddUser;
