import Confirmation from "../confirmation";
// import { useDeleteUserMutation } from "../../../redux/queries";
import { errorToast } from "utils";
import { successToast } from "utils";
import { Trash } from "react-feather/dist";

function DeleteModal({ id, message, useDeleteMutation }) {
    const [mutate] = useDeleteMutation();

    const handleDelete = async () => {
        try {
            await mutate(id).unwrap();
            successToast("deleted successfully!");
        } catch (error) {
            errorToast(error.message);
        }
    };
    return (
        <Confirmation
            title="Delete"
            message={message}
            handleConfirm={handleDelete}
            icon="warning"
        >
            <span
                role="button"
                className="flex items-center gap-3"
            // onClick={(e) => e.preventDefault()}
            >
                <Trash className="me-50" size={15} />
            </span>
        </Confirmation>
    );
}

export default DeleteModal;
