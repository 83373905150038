import Card from "components/card";
import React from "react";
import DataTable from "../dataTable/reactDataTable";
import { useDeleteCategoryMutation, useGetCategoriesQuery } from "../../redux/queries";
import moment from "moment";
import DeleteModal from "../AppModal/deleteModal";
import EditCategoryModal from "./EditCategoryModal";

const columns = [
    {
        name: "Category Name",
        maxWidth: "350px",
        selector: (row) => (
            <div className="flex items-center gap-2 py-2 ">
                <img
                    class="h-14 w-14 rounded"
                    src={row?.logo ? row?.logo : "/assets/images/professional-man.jpeg"}
                    onError={(e) => {

                        e.target.src = "/assets/images/notfound.svg"
                    }}
                    alt="logo"
                />
                <div>
                    <p className="font-semibold ">{row?.name}</p>
                </div>
            </div>
        ),
    },
    {
        name: "Services",
        selector: (row) => (
            <div className="flex items-center gap-1">
                <span className="font-semibold capitalize"> 0 </span>
            </div>
        ),
    },
    {
        name: "Created Date",
        selector: (row) => <span>{moment(row.createdAt).format("DD-MM-YYYY HH:MM")}</span>,
    },
    {
        name: "Status",
        selector: (row) => (
            <div className="flex items-center gap-2">
                <span
                    className={`${row.status === "active" ? "badge-success" : "badge-danger"
                        } capitalize `}
                >
                    {row.status}
                </span>
            </div>
        ),
    },
    {
        name: "Actions",
        selector: (row) => (
            <div className="flex gap-3">
                <div
                    className="flex gap-3"
                    style={{ alignItems: "center" }}
                    onClick={(e) => {
                        e.preventDefault();
                        //   handleEditUser(row);
                        //   toggle();
                    }}
                >
                    {/* <Edit className="me-2" size={15} /> */}
                    <EditCategoryModal user={row} />
                </div>
                <DeleteModal id={row?._id}
                    useDeleteMutation={useDeleteCategoryMutation}
                    message={"Are you sure you want to delete this user?"}
                />
            </div>
        ),
    },
];

const CategoryList = ({ filters }) => {
    console.log("filters",filters)
    const { data, isLoading } = useGetCategoriesQuery(filters);
    return (
        <>
            <DataTable
                loadingMessage="Loading..."
                isLoading={isLoading}
                columns={columns}
                data={data}
                noDataMessage={<p className="my-5 py-5">No Data found!</p>}
            />
        </>
    );
};

export default CategoryList;
