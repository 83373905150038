import "react-international-phone/style.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Button, Input, Label } from "reactstrap";
import { successToast, errorToast } from "utils";
import { useParams } from "react-router-dom";
import { useGetProEducationsQuery, useAddEducationalBackGroundMutation, useUpdateEducationalBackGroundMutation, useDeleteEducationalBackGroundMutation } from "../../../redux/queries";

const defaultValues = {
  fieldOfStudy: "",
  institutionName: "",
  startYear: "",
  endYear: "",
  description: "",
};

const EducationalBackGround = () => {
  const { id } = useParams();
  const [addData] = useAddEducationalBackGroundMutation();
  const [updateData] = useUpdateEducationalBackGroundMutation();
  const [deleteData] = useDeleteEducationalBackGroundMutation();
  const { data, refetch } = useGetProEducationsQuery(id);


  const user = useSelector((state) => state.auth);
  const role = user?.user?.role;

  const [formValues, setFormValues] = useState(defaultValues);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (editData) {
      setFormValues(editData);
    } else {
      setFormValues(defaultValues);
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (editData) {
        const res = await updateData({ id: editData._id, ...formValues }).unwrap();
        successToast(res.message);
      } else {
        console.log("Submitting data:", { id, body: formValues });
        const res = await addData({ id, body: formValues }).unwrap();
        successToast(res.message);
      }
      refetch();
      closeModal();
    } catch (error) {
      errorToast(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData(deleteId).unwrap();

      successToast(res.message);
      closeDeleteModal();
      refetch();
    } catch (error) {
      errorToast(error.message);
    }
  };


  const openModal = (data = null) => {
    setEditData(data);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const openDeleteModal = (id) => {
    setDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteId(null);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <div
        className="bg-white rounded-lg w-full shadow-xl flex flex-col gap-5 px-5 py-10"
        style={{ boxShadow: "0px 0px 20px 0px #101E730F" }}
      >
        <div className="flex justify-between">
          <h1 className="text-[20px] font-BeNational-500">
            Educational Information
          </h1>
          <IoMdAdd
            size={20}
            onClick={() => openModal()}
            className="cursor-pointer"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-6">
          {data?.length > 0 && data.map((item) => (
            <div
              className="bg-white shadow-lg border border-gray-200 rounded-lg overflow-hidden transition-transform transform hover:scale-105"
              key={item._id}
            >
              <div className="p-5 flex flex-col justify-between h-full">
                <div className="flex flex-row justify-between items-start mb-4">
                  <div>
                    <h1 className="text-xl font-semibold text-gray-800">
                      {item.institutionName}
                    </h1>
                    <h2 className="text-gray-600 text-sm">
                      {item.fieldOfStudy}
                    </h2>
                  </div>
                  <div className="flex gap-2">
                    <MdEdit
                      size={20}
                      className="text-blue-500 cursor-pointer hover:text-blue-700"
                      onClick={() => openModal(item)}
                    />
                    <RiDeleteBin6Line
                      size={18}
                      className="text-red-500 cursor-pointer hover:text-red-700"
                      onClick={() => openDeleteModal(item._id)}
                    />
                  </div>
                </div>
                <div>
                  <h3 className="text-gray-700 text-sm mb-2">
                    {item.startYear} To {item.endYear}
                  </h3>
                  <p className="text-gray-500 text-sm max-h-32 overflow-y-auto custom-scrollbar">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {data?.length === 0 && (
          <p className="text-center py-10">No Educational Details</p>
        )}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[1140px] relative">
              <button
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                onClick={closeModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h1 className="text-[20px] font-BeNational-700 mb-5">
                Edit Educational Details
              </h1>

              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-12 gap-5 items-center"
              >
                <div className="col-span-6">
                  <Label htmlFor="fieldOfStudy" required>
                    Degree Title
                  </Label>
                  <Input
                    type="text"
                    id="fieldOfStudy"
                    name="fieldOfStudy"
                    value={formValues.fieldOfStudy}
                    onChange={handleChange}
                    placeholder="Your Field of Study"
                  />
                </div>
                <div className="col-span-6">
                  <Label htmlFor="institutionName" required>
                    Institution Name
                  </Label>
                  <Input
                    type="text"
                    id="institutionName"
                    name="institutionName"
                    value={formValues.institutionName}
                    onChange={handleChange}
                    placeholder="Your institution name"
                  />
                </div>

                <div className="col-span-6">
                  <Label htmlFor="startYear" required>
                    From
                  </Label>
                  <Input
                    type="date"
                    id="startYear"
                    name="startYear"
                    value={formValues.startYear}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6">
                  <Label htmlFor="endYear" required>
                    To
                  </Label>
                  <Input
                    type="date"
                    id="endYear"
                    name="endYear"
                    value={formValues.endYear}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-12">
                  <Label htmlFor="description" required>
                    Description
                  </Label>
                  <Input
                    type="textarea"
                    id="description"
                    name="description"
                    value={formValues.description}
                    onChange={handleChange}
                    rows="6"
                    placeholder="Your Description"
                  />
                </div>

                <div className="col-span-12 flex justify-end">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
        {isDeleteModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[30%] relative">
              <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-900" onClick={closeDeleteModal}>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <h1 className="text-[20px] font-BeNational-700 mb-5">Delete Experience Details</h1>
              <div className="grid grid-cols-12 gap-5 items-center">
                <div className="col-span-12 font-BeNational-300">
                  <h1>Are you sure you want to delete?</h1>
                </div>
                <div className="col-span-12">
                  <Button
                    type="button"
                    isLoading={false}
                    disabled={false}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

export default EducationalBackGround;
