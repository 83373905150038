import React from 'react';
import Swal from 'sweetalert2';

const Confirmation = ({ title, icon = "info", message, onClick, children, handleConfirm }) => {

    const handleCancel = () => {
        console.log('Cancelled.');
    };
    return (
        <div>
            <button className='w-full' onClick={() => {
                // onClick()
                Swal.fire({
                    title: title,
                    text: message,
                    icon: icon,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, proceed',
                    cancelButtonText: 'No, cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleConfirm();
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        handleCancel();
                    }
                })
            }}
            >
                {children}
            </button>
        </div>
    );
}

export default Confirmation;