import Card from "components/card";
import React, { useState } from "react";
import SelectField from "components/fields/SelectField";

import { Field, Form, Formik } from "formik";
import InputField from "components/fields/InputField";
import UserList from "components/UserList";
const domainOptions = [
  { label: "Health", value: "health" },
  { label: "Legal", value: "legal" },
  { label: "Notary", value: "notary" },
  { label: "Administrative", value: "administrative" },
];
const Professionals = () => {
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [filters, setFilters] = useState({ role: "professional" })

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };
  const handleSubmit = (values) => {
    setFilters(values);
  };
  return (
    <>
      <Card extra={"mt-4 !z-5 overflow-hidden"}>
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            All Professionals
          </h4>
        </div>
        <div className="p-5">
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ name: "", status: "", domain: "", role: "professional" }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <div className="grid grid-cols-4 gap-3 ">
                  <div>
                    <Field
                      label="Search By Name"
                      name="name"
                      type="text"
                      placeholder="Enter name"
                      component={InputField}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="status"
                      class="mb-2 mt-1 block text-sm font-bold text-gray-900 dark:text-white"
                    >
                      Domain
                    </label>
                    <Field
                      name="domain"
                      selectorOptions={domainOptions}
                      selectorLabelKey="label"
                      selectorValueKey="value"
                      placeholder="domain"
                      component={SelectField}
                    />
                  </div>
                  {/* <div>
                    <label
                      htmlFor="status"
                      class="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
                    >
                      Status
                    </label>
                    <Field
                      name="status"
                      selectorOptions={statusOptions}
                      selectorLabelKey="label"
                      selectorValueKey="value"
                      placeholder="status"
                      component={SelectField}
                    />
                  </div> */}
               
                  <div className="flex items-end">
                    <button
                      type="submit"
                      // disabled={isSubmitting}
                      className="linear rounded-[20px] mb-1  bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <UserList filters={filters} setFilters={setFilters}/>
      </Card>
    </>
  );
};

export default Professionals;
