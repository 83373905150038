import Card from "components/card";
import React, { useState } from "react";
import { Edit } from "react-feather";
import { Field, Form, Formik } from "formik";
import InputField from "../../../components/fields/InputField";
import { Button } from "../../../components/ui-elements/Button";
import moment from "moment";
import { Link } from "react-router-dom";
import SelectField from "components/fields/SelectField";
import DataTable from "components/dataTable/reactDataTable";
import { useDeleteLocationMutation, useGetlocationsQuery } from "../../../redux/queries";
import DeleteModal from "components/AppModal/deleteModal";

const columns = [
  {
    name: "Location Name",
    maxWidth: "350px",
    selector: (row) => (
      <div>
        <p className="font-semibold ">{row?.name}</p>
      </div>
    ),
  },
  {
    name: "Services",
    selector: (row) => (
      <div className="flex items-center gap-1">
        <span className="font-semibold capitalize"> 0 </span>
      </div>
    ),
  },
  {
    name: "Created Date",
    selector: (row) => <span>{moment(row.createdAt).format("DD-MM-YYYY HH:MM")}</span>,
  },
  {
    name: "Status",
    selector: (row) => (
      <div className="flex items-center gap-2">
        <span
          className={`${row.status === "active" ? "badge-success" : "badge-danger"
            } capitalize `}
        >
          {row.status}
        </span>
      </div>
    ),
  },
  {
    name: "Actions",
    selector: (row) => (
      <div className="flex gap-3">
        <div
          className="flex gap-3"
          style={{ alignItems: "center" }}
          onClick={(e) => {
            e.preventDefault();
            //   handleEditUser(row);
            //   toggle();
          }}
        >
          <Edit className="me-2" size={15} />
        </div>
        <DeleteModal id={row?._id}
          useDeleteMutation={useDeleteLocationMutation}
          message={"Are you sure you want to delete this location?"}
        />
      </div>
    ),
  },
];
const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inActive" },
];

const AllLocations = () => {
  const [filters, setFilters] = useState({})
  const { data, isLoading } = useGetlocationsQuery(filters);
  return (
    <Card extra={"mt-4 !z-5 overflow-hidden"}>
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          All Locations
        </h4>
        <Link
          to={`/admin/add-location`}
        >
          <Button>Add Location</Button>
        </Link>
      </div>

      <div className="px-3">
        <Formik
          initialValues={{ name: "", status: "", date: new Date() }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="grid grid-cols-4 gap-3 pt-5">
                <div>
                  <Field
                    label="Search By Name"
                    name="name"
                    type="text"
                    placeholder="Enter name"
                    component={InputField}
                  />
                </div>
                <div>
                  <label
                    htmlFor="status"
                    class="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
                  >
                    Status
                  </label>
                  <Field
                    name="status"
                    selectorOptions={statusOptions}
                    selectorLabelKey="label"
                    selectorValueKey="value"
                    placeholder="status"
                    component={SelectField}
                  />
                </div>
                <div className="flex items-end">
                  <Button> Search </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <DataTable
        loadingMessage="Loading..."
        isLoading={isLoading}
        columns={columns}
        data={data}
        noDataMessage={<p className="my-5 py-5">No Data found!</p>}
      />
    </Card>
  );
};

export default AllLocations;
