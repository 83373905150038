export const domainsOptions = [
  {
    value: "health",
    label: "Health",
  },
  {
    value: "legal",
    label: "Legal",
  },
  {
    value: "administrative",
    label: "Administrative",
  },
  {
    value: "notary",
    label: "Notary",
  },
];
