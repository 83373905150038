import { categoriesTags } from "../tags";
import { injectApiEndpoints } from "../api";
import { transformResponse } from "./helpers";

const url = "/category";
const appendUrl = (segment) => `${url}/${segment}`;

const categorysApis = injectApiEndpoints({
  endpoints: (builder) => ({
    createCategory: builder.mutation({
      query: (body) => ({
        url,
        method: "post",
        body,
      }),
      invalidatesTags: [categoriesTags],
    }),
    getCategories: builder.query({
      query: (filters = {}) => {
        let query = "";
        if (filters.domain) query = `domain=${filters.domain}&`;
        if (filters.name) query = `name=${filters.name}&`;
        return {
          url: `${url}?${query}`,
          method: "get",
        };
      },
      transformResponse: transformResponse,
      providesTags: [categoriesTags],
    }),
    updateCategory: builder.mutation({
      query: ({ id, ...body }) => ({
        url: appendUrl(id),
        method: "put",
        body,
      }),
      invalidatesTags: [categoriesTags],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: appendUrl(id),
        method: "delete",
      }),
      invalidatesTags: [categoriesTags],
    }),
    getCategoriesOptionsByDomain: builder.query({
      query: (domain) => ({
        url: appendUrl(`domain/options/${domain}`),
        method: "get",
      }),
      transformResponse,
      invalidatesTags: [categoriesTags],
    }),
  }),
});
export const {
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesOptionsByDomainQuery,
} = categorysApis;
