import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import logo from "../../assets/svg/logo.svg";
import { Link } from "react-router-dom";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>
      <Link
        to="/admin/dashboard"
        className={`mx-[56px] mt-[30px] flex items-center`}
      >
        <div>
          {/* <img className="h-[50px] mb-0" src={logo} alt="logo" /> */}
          <h1 className="text-3xl font-bold">
            Be<span className="  text-[#EA494F]">National</span>
          </h1>
         
        </div>
      </Link>
      <div className="mb-7 mt-[20px]  bg-gray-300 dark:bg-white/30" />
      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>
    </div>
  );
};
export default Sidebar;
