import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import RtlLayout from "./layouts/rtl";
import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setToastMessage } from "./redux/commonSlice";
import { Toaster } from "react-hot-toast";
import 'react-quill/dist/quill.snow.css';

const App = () => {
  const { toastMessage, toastStatus } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toastStatus) {
      toast.success(toastMessage);
    } else if (toastStatus == false) {
      toast.error(toastMessage);
    }
    dispatch(setToastMessage({ message: null, success: null }));
  }, [toastMessage, toastStatus]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className="custom-toast-container"
      />
      <Toaster position="top-right" />
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </>
  );
};

export default App;
