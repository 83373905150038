import React from "react";
import DataTable from "../dataTable/reactDataTable";
import EditUserModal from "./EditUserModal";
import { useGetServicesQuery, useDeleteServiceMutation } from "../../redux/queries";
import DeleteModal from "components/AppModal/deleteModal";

const columns = [
    {
        name: "User",
        maxWidth: "350px",
        selector: (row) => (
            <div className="flex items-center gap-2 py-2">
                <img
                    className="h-10 w-10 object-contain rounded-full"
                    src={row.user.avatar ? row?.user?.avatar : "/assets/images/images.png"}
                    alt="Rounded avatar"
                />
                <div>
                    <p className="font-semibold">{row?.name}</p>
                    <span className="text-gray-700 lowercase truncate" title={row?.user?.name}>{row?.user?.name}</span>
                </div>
            </div>
        ),
    },
    {
        name: "designation",
        selector: (row) => (
            <div className="flex items-center gap-1">
                <span className="font-semibold capitalize">{row?.designation?.name}</span>
            </div>
        ),
    },
    {
        name: "Description",
        selector: (row) => (
            <div className="flex items-center gap-1">
                <span className="font-semibold capitalize w-80 truncate">{row?.description}</span>
            </div>
        ),
    },
    {
        name: "Status",
        selector: (row) => (
            <span className="badge-success">Active</span>
        ),
    },
    {
        name: "Actions",
        selector: (row) => (
            <div className="flex gap-3">
                {/* <EditUserModal user={row} /> */}
                <DeleteModal id={row.id}
                    message="Are you sure you want to delete this service?"
                    useDeleteMutation={useDeleteServiceMutation} />
            </div>
        ),
    },
];
const ServiceList = ({ filters }) => {
    const { data, isLoading } = useGetServicesQuery(filters);
    console.log("🚀 ~ ServiceList ~ data:", filters, data)

    return (
        <>
            <DataTable
                loadingMessage="User fetching..."
                isLoading={isLoading}
                columns={columns}
                data={data}
                noDataMessage={<p className="my-5 py-5">No Service found!</p>}
            />
        </>
    );
};

export default ServiceList;
