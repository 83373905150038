import React from "react";

const CircularPagination = (
  { currentPage, totalPages, onPageChange }
) => {
  const getItemProps = (page) => ({
    className: `px-3 py-1 border rounded-full cursor-pointer ${currentPage === page ? "bg-gray-500 text-white" : "bg-gray-200 text-gray-700"}`,
    onClick: () => onPageChange(page),
  });

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const getPageRange = () => {
    const delta = 2; 
    const startPage = Math.max(1, currentPage - delta);
    const endPage = Math.min(totalPages, currentPage + delta);

    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    const pages = [];

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <div className="flex items-center gap-4 mb-3 mr-3 mt-2">
      <button
        className="px-3 py-1 border rounded-full bg-gray-200 text-gray-700"
        onClick={prev}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <div className="flex items-center gap-2">
        {getPageRange().map((page, index) => (
          <button
            key={index}
            {...(typeof page === 'number' ? getItemProps(page) : {})}
            className={`px-3 py-1 border rounded-full cursor-pointer ${currentPage === page ? "bg-gray-500 text-white" : "bg-gray-200 text-gray-700"}`}
          >
            {page}
          </button>
        ))}
      </div>
      <button
        className="px-3 py-1 border rounded-full bg-gray-200 text-gray-700"
        onClick={next}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default CircularPagination;
