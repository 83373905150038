import { injectApiEndpoints } from "../api";
import { transformResponse } from "./helpers";

const baseExperienceUrl = '/experience';
const appendExperienceUrl = (segment) => `${baseExperienceUrl}/${segment}`;

export const ProfessionalApi = injectApiEndpoints({
  endpoints: (build) => ({
    updateSocialLinks: build.mutation({
      query: ({ id, socialLinks }) => ({
        url: `/user/update-pro-social/${id}`, 
        method: 'put',
        body: socialLinks,
      }),
    }),
    addEducationalBackGround: build.mutation({
      query: ({id, body}) => ({
        url: `/education/pro-education/${id}`, 
        method: "post",
        body,
      }),
    }),
    updateEducationalBackGround: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/education/${id}`, 
        method: "put",
        body,
      }),
    }),
    deleteEducationalBackGround: build.mutation({
      query: (id) => ({
        url: `/education/${id}`, 
        method: "delete",
      }),
    }),
    getProEducations: build.query({
      query: (id) => ({
        url: `/education/pro-education/${id}`,
        method: "get",
      }),
      transformResponse,
    }),
    getProExperience: build.query({
      query: (id) => ({
        url: `/experience/pro-experience/${id}`,
        method: "get",
      }),
      transformResponse,
    }),

    getEducationalDetails: build.query({
      query: () => ({
        url: `/education`, 
        method: "get",
      }),
      transformResponse,
    }),
    addExperienceBackGround: build.mutation({
      query: ({id, body}) => ({
        url: `/experience/pro-experience/${id}`, 
        method: "post",
        body,
      }),
    }),
    
    deleteExperienceBackGround: build.mutation({
      query: (id) => ({
        url: appendExperienceUrl(id),
        method: "delete",
      }),
    }),
    getExperienceDetails: build.query({
      query: () => ({
        url: baseExperienceUrl,
        method: "get",
      }),
      transformResponse,
    }),
    getExperienceDetailById: build.query({
      query: (id) => ({
        url: appendExperienceUrl(id),
        method: "get",
      }),
      transformResponse,
    }),
    updateExperienceDetailById: build.mutation({
      query: ({ id, body }) => ({
        url: appendExperienceUrl(id),
        method: "put",
        body,
      }),
    }),
  }),
});

export const {
  useUpdateSocialLinksMutation,
  useAddEducationalBackGroundMutation,
  useUpdateEducationalBackGroundMutation,
  useDeleteEducationalBackGroundMutation,
  useGetEducationalDetailsQuery,
  useGetProEducationsQuery,
  useGetProExperienceQuery,
  useAddExperienceBackGroundMutation,
  useDeleteExperienceBackGroundMutation,
  useGetExperienceDetailsQuery,
  useGetExperienceDetailByIdQuery,
  useUpdateExperienceDetailByIdMutation,
} = ProfessionalApi;
