
import React from "react";
import DomainCategories from "components/Category/DomainCategories";

const HealthCategories = () => {
  return (
    <>
      <DomainCategories domain="health" />
    </>
  );
};

export default HealthCategories;
